"use client";
import React from "react";
import Image from "next/image";
import imgError from "@/../public/images/abstract/Error.png";
export default function ErrorBoundary({ error }: { error: Error }) {
  return (
    <div>
      <section className="px-page-xl pt-16">
        <div className="py-16 flex flex-col gap-16 md:flex-row">
          <div className="flex flex-col w-full md:w-2/4 justify-center">
            <div className="text-4xl text-gbci-primary font-bold">
              Oops! An unexpected error has ocurred
            </div>
            <div className="text-3xl font-bold py-8">
              The following error has been detected:
            </div>
            <div className="text-xl font-bold py-8">{error.message}</div>
            <div className="text-3xl text-gbci-accent font-bold py-8">
              If the error keeps appearing please contact us and let us know!
            </div>
          </div>
          <div className="flex flex-col w-full md:w-2/4">
            <Image className="w-full" src={imgError} alt="404 img" />
          </div>
        </div>
      </section>
    </div>
  );
}
